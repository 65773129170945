.blue-yellow-gradient {
	background: rgb(244, 175, 46);
	background: linear-gradient(
		221deg,
		rgba(244, 175, 46, 0.9) 5%,
		rgba(103, 161, 154, 0.9) 88%
	);
	filter: blur(3);
}

.blue-yellow-gradient-dark {
	background: rgb(244, 175, 46);
	background: linear-gradient(
		221deg,
		rgba(244, 175, 46, 0.6) 5%,
		rgba(103, 161, 154, 0.6) 88%
	);
	filter: blur(3);
}

.yellow-blue-gradient {
	background: rgb(244, 175, 46);
	background: linear-gradient(
		45deg,
		rgba(244, 175, 46, 0.9) 5%,
		rgba(134, 207, 198, 0.9) 88%
	);
}
